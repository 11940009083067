/* eslint-disable no-param-reassign */
import store from '../adStore';

function trackAd({ pid, slot }) {
  if (typeof document !== 'undefined' || typeof window !== 'undefined') {
    const { pathname } = window.location;
    const { referrer } = document;

    const image = new Image(1, 1);
    const urlParams = new URLSearchParams({
      pid,
      r: referrer,
      p: pathname,
      s: slot,
    });
    image.src = `/txs.gif?${urlParams.toString()}`;
    document.body.appendChild(image);
  }
}

function resetTime(ad) {
  ad.lastViewStarted = 0;
  ad.totalViewTime = 0;
}
function updateTime(ad) {
  if (!ad) {
    return;
  }
  const lastStarted = ad.lastViewStarted || 0;
  const currentTime = performance.now();

  if (lastStarted) {
    const diff = currentTime - lastStarted;

    ad.totalViewTime = parseFloat(ad.totalViewTime || 0) + diff;
  }

  ad.lastViewStarted = currentTime;
}

const defaultOptions = {
  adTTL: 60000,
  observer: {
    root: null,
    threshold: [0.0, 0.75],
  },
};
function createObserver(target, { hooks = {}, options = {} }) {
  const config = {
    ...defaultOptions,
    ...options,
  };

  const {
    onVisible = () => null,
    onHidden = () => null,
    onReplace = () => null,
  } = hooks;

  store.ads.set(parseInt(target.pid, 10), target);

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      const { dataset: { pid } = {} } = entry.target;
      const ad = store.ads.get(parseInt(pid, 10)) || {};
      if (entry.isIntersecting) {
        if (entry.intersectionRatio >= 0.75) {
          updateTime(ad);
          onVisible();
          if (!ad.tracked) {
            trackAd(ad);
            ad.tracked = true;
          }
        }
      } else {
        updateTime(ad);
        onHidden();
        if (
          entry.intersectionRatio === 0.0 &&
          ad.totalViewTime >= config.adTTL
        ) {
          resetTime(ad);
          onReplace();
        }
      }
    });
  }, config.observer);
  observer.observe(target.el);
  return () => observer.unobserve(target.el);
}

export default createObserver;
